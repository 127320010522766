import { SpeedInsights } from "@vercel/speed-insights/next";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import { useEffectOnce } from "react-use";
import { setAxiosFactory, setBaseUrl } from "ui/api-hooks/query/helpers";
import { ThemeCustomizer } from "ui/components/theme-customizer";
import { ThemeModeProvider } from "ui/components/theme-mode-provider";
import { ThemeSwitcher } from "ui/components/theme-switcher";
import { useWindowEventListener, useWindowResize } from "ui/hooks/listeners";
import { useDebounce } from "ui/hooks/useDebounce";
import { captureEvent } from "ui/lib/captureEvent";
import { SESSION_KEY, WO_EMAIL_CAPTURE_COOKIE_NAME } from "ui/lib/constants";
import { pageview } from "ui/lib/gtag";
import { identify } from "ui/lib/identify";
import { FontProvider } from "web/components/font-provider";
import { getLayout } from "web/components/page";
import { queryClient } from "web/helpers/queryClient";
import { AppProvider } from "web/state-containers/app";
import "web/styles/globals.css";

const handleSetAxiosFactory = () => {
  const session = Cookies.get(SESSION_KEY);
  if (!session) return;

  let instanceFactory = () =>
    axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL + "/v1",
      headers: {
        Authorization: `Bearer ${session}`,
      },
    });

  setAxiosFactory(instanceFactory);
};

setBaseUrl(process.env.NEXT_PUBLIC_API_BASE_URL + "/v1");
handleSetAxiosFactory();

function MyApp({ Component, pageProps }) {
  const [routeChangeActive, setRouteChangeActive] = useState(false);
  const debouncedRouteChangeActive = useDebounce(routeChangeActive, 250);

  const router = useRouter();
  const Layout = getLayout(Component);

  useEffect(() => {
    handleSetAxiosFactory();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const { ttclid } = router.query;
    if (!ttclid) return;
    Cookies.set("ttclid", ttclid);
  }, [router]);

  useEffect(() => {
    const handleRouteChangeStart = (event) => {
      if (window.location.pathname === event.split("?")[0]) return;
      setRouteChangeActive(true);
    };
    const handleRouteChangeEnd = () => setRouteChangeActive(false);

    const handleRouteChangeComplete = (url) => {
      handleRouteChangeEnd();
      pageview(url);
      window?.analytics?.page?.();
      window?.scrollTo?.(0, 0);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeComplete);
    };
  }, [router.events, router]);

  useEffectOnce(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const email = Cookies.get(WO_EMAIL_CAPTURE_COOKIE_NAME);
    if (!!email) {
      identify({ email });
    }
  }, []);

  useWindowResize(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useWindowEventListener(
    "beforeunload",
    () => {
      captureEvent({
        action: "page_closed",
      });
    },
    []
  );

  return (
    <FontProvider>
      <AnimatePresence>
        {debouncedRouteChangeActive ? (
          <motion.div
            transition={{ duration: 0.25 }}
            animate={{ opacity: [0, 1] }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[100] flex text-foreground bg-secondary/25 backdrop-blur center"
          >
            <motion.div
              transition={{ duration: 0.25 }}
              animate={{ scale: [0, 1], opacity: [0, 1] }}
              // exit={{ scale: 5, opacity: 0 }}
            >
              <motion.svg
                className="w-8 h-8 mr-3 -ml-1 text-foreground animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </motion.svg>
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <ThemeModeProvider attribute="class" defaultTheme="dark" enableSystem>
            <Layout options={Component.options || {}}>
              <Head>
                <meta charSet="UTF-8" />
                <meta name="theme-color" content="#0e0e0e" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, viewport-fit=cover"
                />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta
                  name="apple-mobile-web-app-status-bar-style"
                  content="black"
                />
              </Head>
              <Component {...pageProps} />
              <ThemeCustomizer />
              <ThemeSwitcher />
            </Layout>
          </ThemeModeProvider>
        </AppProvider>
      </QueryClientProvider>
      <SpeedInsights sampleRate={0.2} />
    </FontProvider>
  );
}

export default MyApp;

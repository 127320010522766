globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"a96996ca98220520f3a383b9df3663628bc042d0"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://5a8429e11a054f0d80fad97c7ee2bdc4@o458855.ingest.sentry.io/6271383",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [],

  networkDetailAllowUrls: [
    "https://apollo.joinladder.com",
    "https://apollo.dev.joinladder.com",
    "https://dev.joinladder.com",
    "https://joinladder.com",
    "https://rocky-api.dev.ltdev.io",
    "https://rocky-api.prod.ltdev.io",
  ],

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
